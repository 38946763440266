import React from 'react';
import logo from './img/logo_cropped.png'
import { FaEnvelope } from 'react-icons/fa';
const LandingPage = () => {
  return (
    <div className="container">
      <img src={logo} alt="Logo" className="logo" />
      <p className="tagline">A Healthy Snacks Brand</p> 
      <h1>Launching Soon!</h1>
      <div className="contact">
        <p className="contact-text">Got a query? Contact us:</p>
        {/* <FaEnvelope className="contact-icon" size={24} /> */}
        <p><a href="mailto:vishwas@grainfull.in">vishwas@grainfull.in</a></p>
      </div>
    </div>
  );
};

export default LandingPage;
