
import './App.css';
import logo from './img/logo.png'
import LandingPage from './LandingPage';
function App() {
  return (
    <div>
      <LandingPage/>
    </div>
  );
}

export default App;


  // <div className="container">
    {/* <img src={logo} alt="Logo" className="logo" />
    <h1>Launching Soon!</h1>
    <p className="contact">Contact: <a href="mailto:Vishwas@grainfull.in">Vishwas@grainfull.in</a></p> */}
  // {/* </div> */}